import React from 'react';
import bemCn from 'bem-cn';
import { NAV_ALT } from '../navigation/navigation.consts';
import { NavLink } from 'react-router-dom';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { RouteFormatter } from '../../routes';
import PictureSet from '../picture-set';
import logoMin from '../../assets/img/sniffspot-logo-min.png';
import logo from '../../assets/sniffspot-logo.png';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import './style.scss';

const b = bemCn('navigation-logo');

const NavigationLogo = ({ className = '', full, isHost }) => {
    const isMobile = useMobileScreen();
    const src = isMobile ? logoMin : logo;
    const width = isMobile ? 50 : 196;
    const height = isMobile ? 34 : 34;
    const url = isHost ? RouteFormatter.hostAccount() : RouteFormatter.home();

    return (
        <NavLink className={className} to={url}>
            {full ? (
                <SvgSnifLogo height={32} className="ms-2" />
            ) : (
                <PictureSet imgs={src} alt={NAV_ALT} className={b('img')} width={width} height={height} method="eager" />
            )}
        </NavLink>
    );
};

export default NavigationLogo;
