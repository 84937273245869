import React from 'react';
import { connect } from 'react-redux';
import { NAV_ITEMS } from './navigation.consts';
import { RouteFormatter } from '../../routes';
import CommonNavigation from '../common-navigation';
import SvgAddCircle from 'src/assets/svg/SvgAddCircle.svg';

const NAV_HOST_ITEMS = [
    NAV_ITEMS.ACCOUNT_HOST_EDIT,
    NAV_ITEMS.MY_EARNINGS,
    NAV_ITEMS.ADD_SPOT,
    NAV_ITEMS.SEPARATOR,
    NAV_ITEMS.TRUST_SAFETY,
    NAV_ITEMS.HELP_CENTER,
    NAV_ITEMS.SEPARATOR,
    NAV_ITEMS.SWITCH_TO_GUEST,
    NAV_ITEMS.SIGN_OUT,
];

const NavigationHost = ({ isMenuOpened, toggleMenu, inactSpot }) => {
    const hostNav = [...NAV_HOST_ITEMS];
    hostNav[2] = inactSpot
        ? {
              title: 'Add a spot',
              icon: <SvgAddCircle width={20} height={20} />,
              url: RouteFormatter.createSpot({ id: inactSpot.id }),
              type: 'internal',
          }
        : NAV_ITEMS.ADD_SPOT;

    return <CommonNavigation logoLink={{ url: RouteFormatter.hostAccount() }} config={hostNav} isMenuOpened={isMenuOpened} toggleMenu={toggleMenu} />;
};

export default connect((state) => ({
    inactSpot: state.userDetails.spots.find((s) => !s.publishedAt),
}))(NavigationHost);
