import React, { useState } from 'react';

export default function withFilters(defaultFilter = {}) {
    return (Component) => {
        return function Wrapped(props) {
            const [filter, updateFilter] = useState(defaultFilter);

            function setFilter(filterInfo) {
                updateFilter({ ...filter, ...filterInfo });
            }

            return <Component {...props} filter={filter} setFilter={setFilter} />;
        };
    };
}
