import React from 'react';
import { compose } from 'recompose';
import withFilters from './withFilters';
import { withRouter } from 'react-router';
import withQueryParams from './withQueryParams';
import { stringifyQuery } from '../../helpers/routing';

function getDeps(defaultFilter) {
    return compose(withRouter, withFilters(defaultFilter), withQueryParams);
}

export default function prepareHoc(defaultFilter) {
    return function withReservationsFilters(Component) {
        function Wrapper({ parsedRouteParams, history, setFilter, filter, ...props }) {
            React.useEffect(() => {
                setFilter(queryToApiFilter(parsedRouteParams));
            }, []);

            function proxySetFilter(filter) {
                history.push({ search: stringifyQuery(filter) });
                setFilter(filter);
            }

            function getFilter() {
                return filter;
            }

            return <Component {...props} filter={getFilter()} setFilter={proxySetFilter} />;
        }

        return getDeps(defaultFilter)(Wrapper);
    };
}

export function queryToApiFilter(query = {}) {
    const { status, to, from, dateSelect, spots } = query;

    const finalFilter = {};

    if ('string' === typeof status) {
        finalFilter.status = [status];
    } else if (Array.isArray(status)) {
        finalFilter.status = status;
    }

    if ('string' === typeof spots) {
        finalFilter.spots = [spots];
    } else if (Array.isArray(spots)) {
        finalFilter.spots = spots;
    }

    if (to) {
        finalFilter.to = new Date(to).toISOString();
    }

    if (from) {
        finalFilter.from = new Date(from).toISOString();
    }

    finalFilter.dateSelect = dateSelect;

    return finalFilter;
}
