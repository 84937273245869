import queryString from 'query-string';
import { ROUTE_TITLE_LINK } from '../consts/app.consts';
import { RouteFormatter } from '../routes';

export const parseQuery = queryString.parse;
export const stringifyQuery = queryString.stringify;

export const getDataFromUrl = (url) => {
    if (
        url.startsWith('/listings/water-parks') ||
        url.startsWith('/listings/hiking-trails') ||
        url.startsWith('/listings/fenced-parks') ||
        url.startsWith('/listings/dog-parks-fields') ||
        url.startsWith('/listings/dog-agility-parks') ||
        url.startsWith('/listings/dog-beaches') ||
        url.startsWith('/listings/indoor-dog-parks')
    ) {
        return { title: '', link: RouteFormatter.home() };
    }

    if (url.startsWith('/listings/')) {
        return { title: '', link: RouteFormatter.listings({}) };
    }

    const dataUrl = ROUTE_TITLE_LINK[url];
    let title = dataUrl ? dataUrl[0] : '';
    let link = dataUrl ? dataUrl[1] : null;

    if (!dataUrl) {
        const key = Object.keys(ROUTE_TITLE_LINK).find((k) => url.startsWith(k));
        if (key) {
            title = ROUTE_TITLE_LINK[key][0];
            link = ROUTE_TITLE_LINK[key][1];
        }
    }

    return { title, link };
};
