import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { getTestWithProb } from '../../helpers/abTest';
import { appleStoreLink, googlePlayLinkUrl } from '../../consts/link.consts';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import { PLATFORM } from '../../consts/platform.consts';
import { RouteFormatter } from '../../routes';
import CookieService from '../../services/cookie.service';
import useGetProbabilityTest from '../../services/hooks/useGetProbabilityTest';
import useMutateViewAppDownloadPrompt from '../../services/hooks/useMutateViewAppDownloadPrompt';
import { checkPlatform } from '../../utils';
import PictureSet from '../picture-set';

import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import appLogo from 'src/assets/img/sniffspot-app-store-logo.png';
import './style.scss';

const routesToShowPrompt = [
    RouteFormatter.home(),
    RouteFormatter.listings(),
    RouteFormatter.listings2(),
    RouteFormatter.visitList(),
    RouteFormatter.subscriptions(),
    RouteFormatter.guestMessages(),
    RouteFormatter.invite(),
    RouteFormatter.favorites(),
];

const AppDownloadModal = ({ userDetails }) => {
    const location = useLocation();
    const { getProbTest } = useGetProbabilityTest();
    const { mutateViewAppDownloadPrompt } = useMutateViewAppDownloadPrompt();
    const [showModal, setShowModal] = useState(false);

    const userID = useSelector((state) => state?.userDetails?.id);
    const isLoggedIn = !!userID;
    const platform = checkPlatform();
    const isMobile = [PLATFORM.ANDROID, PLATFORM.IOS].includes(platform);
    const isAndroid = platform === PLATFORM.ANDROID;
    const isBooking = location.hash === '#web_spot';

    const handleClose = () => {
        setShowModal(false);
        CookieService.set(COOKIE_PARAM_NAME.APP_DOWNLOAD_PROMPT_SHOWN, 'true', { expires: 0 });
    };

    const handleOpenApp = () => {
        const appStoreUrl = isAndroid ? googlePlayLinkUrl : appleStoreLink;
        window.location.href = appStoreUrl;
    };

    useEffect(() => {
        const checkTestGroup = async () => {
            if (!isLoggedIn || !isMobile) {
                return;
            }

            if (CookieService.get(COOKIE_PARAM_NAME.APP_DOWNLOAD_PROMPT_SHOWN) === 'true') {
                return;
            }

            if (isBooking) {
                return;
            }

            const isMatchingRoute = routesToShowPrompt.some((route) =>
                matchPath(location.pathname, {
                    path: route,
                    exact: true,
                })
            );

            if (!isMatchingRoute) {
                return;
            }

            const isTestGroup = await getTestWithProb({
                getProbTest,
                user: userDetails,
                cookie: COOKIE_PARAM_NAME.APP_DOWNLOAD_PROMPT_TEST,
                userTestGroup: USER_TEST_GROUP.APP_DOWNLOAD_PROMPT_TEST,
            });

            setShowModal(isTestGroup);

            if (isTestGroup !== null) {
                mutateViewAppDownloadPrompt({
                    variables: {
                        testGroup: isTestGroup,
                        sessionUuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID),
                    },
                });
            }
        };

        checkTestGroup();
    }, [isLoggedIn, location.pathname]);

    if (!showModal) {
        return null;
    }

    return (
        <div className="app-download-modal">
            <div className="overlay" />
            <div className="content">
                <button type="button" className="content_close snif-button snif-button--icon" onClick={handleClose} aria-label="Close modal">
                    <SvgCross2 />
                </button>
                <div className="content_container">
                    <div className="content_header mb-3">
                        <div className="content_logo">
                            <PictureSet imgs={appLogo} alt="Sniffspot App" className="content_logo-image" />
                        </div>
                        <h2 className="snif-m2 snif-semibold">Get the Sniffspot app</h2>
                        <p className="snif-p snif-regular">Enjoy a smoother experience with our mobile app!</p>
                    </div>

                    <div className="content_body">
                        <button type="button" className="snif-p snif-semibold snif-button snif-button--primary mb-3" onClick={handleOpenApp}>
                            Open app
                        </button>

                        <button type="button" className="snif-s1 snif-medium snif-button snif-button--text" onClick={handleClose}>
                            Continue with mobile web
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.userDetails,
});

export default connect(mapStateToProps)(AppDownloadModal);
