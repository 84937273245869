import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { apiDomain } from '../../consts/env.consts';
import CookieService from '../../services/cookie.service';
import { RouteFormatter } from '../../routes';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { selectUserInfo, selectUserInfoLoading, selectUserUnver } from '../../selectors/user.selector';
import Preloader from '../preloader';

const PrivateRoute = (props) => {
    const { user, userLoading, hostOnly, history, isUnverified, location, redirectTo = null } = props;

    const redirectRoute = () => {
        CookieService.set(COOKIE_PARAM_NAME.REDIRECT, apiDomain + location.pathname + location.search);
        window.location.replace(RouteFormatter.signUp());
    };

    useEffect(() => {
        if (userLoading) {
            return;
        }

        if ((!user || !user.id) && redirectTo === null) {
            redirectRoute();
            return;
        } else if (isUnverified) {
            CookieService.set(COOKIE_PARAM_NAME.REDIRECT_ONBOARD, apiDomain + location.pathname + location.search);
            history.push(RouteFormatter.finishYourAccount());
        } else if (hostOnly && !user.hostMode) {
            history.replace(RouteFormatter.guestAccount());
            return;
        }
    }, [user, userLoading]);

    if (userLoading || !user) {
        return <Preloader fullWindow />;
    }

    if (!userLoading && !user?.id && redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return <Route {...props} />;
};

export default compose(
    connect((state) => ({
        isUnverified: selectUserUnver(state),
        user: selectUserInfo(state),
        userLoading: selectUserInfoLoading(state),
    })),
    withRouter
)(PrivateRoute);
