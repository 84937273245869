import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import isbot from 'isbot';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import queryString from 'query-string';
import { connect } from 'react-redux';
import CookieService from '../../services/cookie.service';
import { AUTH_TESTS } from '../../pages/listings/auth-test.const';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import useGetUserName from '../../services/hooks/useGetUserName';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import MobileTopBarSignup from '../mobile-top-bar-signup';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import useGetSpotHostShort from '../../services/hooks/useGetSpotHostShort';
import useUserDetailsShort from '../../services/hooks/useGetUserDetailsShort';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import useGetProbabilityTest from '../../services/hooks/useGetProbabilityTest';
import { getTestWithProbSSR } from '../../helpers/ssr';
import withReservationsFilters from '../../services/hocs/withReservationFilters';
import { selectBlockedUserIds } from '../../selectors/user.selector';
import useMutateViewTopBarSignup from '../../services/hooks/useMutateViewTopBarSignup';
import { setAlertFromFilters, setUserLocationThunk, updateUserLocation } from '../../actions/user.actions';
import { SUB_TYPE_PARK_LISTINGS } from '../../consts/type-park.consts';
import { getSpotsListBoxMobile } from '../../actions/spot.actions';
import { LISTING_SEARCH_MODS } from '../../consts/listings-search.consts';
import { getDataFromUrl } from '../../helpers/routing';
import { RouteFormatter } from '../../routes';
import {
    selectChannelIdByGuestAndSpot,
    selectDialogIdBySpotId,
    selectDialogInterlocutor,
    selectDialogSpot,
} from '../../selectors/messaging.selector';
import NavigationLayout from '../navigation-layout';
import NavigationLogo from '../navigation-logo';
import { NAV_ITEMS } from '../navigation/navigation.consts';
import './style.scss';

const SniffButton = loadable(() => import('../sniff-button'));
const ChatboxHeader = loadable(() => import('./render-chatbox-header'));
const HeaderLogoWrapper = loadable(() => import('../header-logo-wrapper'));
const RenderHostButton = loadable(() => import('./render-host-button'));
const RenderListingHeader = loadable(() => import('./render-listing-header'));
const RenderMobileDefaultRight = loadable(() => import('./render-mobile-default-right'));

const b = bemCn('navigation-guest');

const messagesUrl = ['/guest_messages/', '/host_messages/'];

const RenderMobileBack = ({ backButtonAction }) => (
    <div className="back-icon" onClick={backButtonAction}>
        <SvgChevronLeft height={14} />
    </div>
);

const Index = ({
    channelId,
    interlocutor,
    center,
    filtersToAlert,
    isMenuOpened,
    isAuthenticated,
    isFixed,
    isShowMoreSpotsBtn,
    isSmartBannerShow,
    isHost = false,
    history,
    navConfig = null,
    showSpotBtn = false,
    mySpot: spot,
    toggleMenu,
    userDetails,
    setFilter,
    filter,
    onGetSpotsListMobile,
    setUserLocation,
    cityTest,
    blockedUserIds,
    messageList,
}) => {
    const isMobile = useMobileScreen();
    const { getUserName, userName } = useGetUserName();
    const { getProbTest } = useGetProbabilityTest();
    const { mutateTopBarSignup } = useMutateViewTopBarSignup();
    const [mode, setMode] = useState(LISTING_SEARCH_MODS.LIST_VIEW);
    const [showNav, setShowNav] = useState(true);
    const [inputFocus, setInputFocus] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [myInterlocutor, setMyInter] = useState(null);
    const [profileName, setProfileName] = useState('');
    const [filterCount, setFilterCount] = useState(false);
    const [isBarSignupTest, setIsBarSignupTest] = useState(false);
    const hasSpots = userDetails && userDetails.spots.length > 0;
    const hasSpotNotLive = userDetails.spots.length === 1 && !userDetails.spots[0].publishedAt;
    const myUrl = history.location.pathname;
    const isHome = myUrl == RouteFormatter.home() || myUrl == RouteFormatter.app();
    const isSniffpassFreeTrial = myUrl == RouteFormatter.sniffpassTrial();
    const isApp = myUrl == RouteFormatter.app();
    const isFavorites = myUrl == RouteFormatter.favorites();
    const isHostDash = myUrl == RouteFormatter.hostAccount();
    const isHostInbox = myUrl == RouteFormatter.hostMessages();
    const isReservationList = myUrl == RouteFormatter.reservationList();
    const isBlog = myUrl == RouteFormatter.blog();
    const isEditSpot = myUrl.match('/host_account/spots/(\\d+)/edit');
    const isCalendar = myUrl.match('/host_account/calendar/(\\d+)');
    const isMemberships = myUrl.match('/host_account/memberships/(\\d+)');
    const isResDetail = myUrl.match('/host_account/reservations/(\\d+)');
    const isSpotDetail = myUrl.startsWith('/listings/') && spot.id && myUrl.includes(spot.id);
    const isProfile = myUrl.startsWith('/profile/');
    const isReserve = myUrl.startsWith('/reserve/');
    const isBlogSub = myUrl.startsWith('/blog/');
    const isDogNames = myUrl.startsWith('/dog-names');
    const isDogRescues = myUrl.startsWith('/dog-rescues');
    const isMessage = myUrl.startsWith(messagesUrl[0]) || myUrl.startsWith(messagesUrl[1]);
    const dataUrl = !isHome ? getDataFromUrl(myUrl) : {};
    const calSpotId = (isCalendar || isMemberships) && myUrl.split('/')[3];
    const publishedSpot = userDetails.spots.filter((s) => s.publishedAt);
    const isStartListings = !cityTest && myUrl.startsWith('/listings/');
    const regexCity = /[a-zA-Z]+-([a-zA-Z]{2})$/;
    const regexCitySub = /(-parks|-fields|-trails|-beaches)$/;
    const isCityPage = myUrl.startsWith('/listings/') && (regexCity.test(myUrl) || regexCitySub.test(myUrl.split('/')[2]));
    let isListing =
        !isSpotDetail &&
        (cityTest ||
            isCityPage ||
            myUrl == RouteFormatter.listings({}) ||
            myUrl == RouteFormatter.listings2({}) ||
            myUrl == RouteFormatter.listingsVideo({}));
    const { getUserDetailsShort, userDetails: myUserChatData = null } = useUserDetailsShort();
    const { getSpotData, spotData = null } = useGetSpotHostShort();

    const canHideNavBar = !isEditSpot && !isResDetail && !isDogNames && !isBlogSub && !isDogRescues;
    const isSpotTopBar = isSpotDetail && userDetails.testGroups[USER_TEST_GROUP.SPOT_DETAIL_TOP_BAR];
    const topBarHeight = isListing ? { height: isBarSignupTest ? '129px' : '72px', flexDirection: 'column' } : {};

    const hasUnconsumedMessage = messageList
        ?.filter((message) => message.hasUnreadMessage)
        .filter((message) => !blockedUserIds?.includes(message.interlocutor?.id));

    let y = window.scrollY;
    let navTitle = isSpotDetail ? spot.title : dataUrl.title;

    if (isEditSpot) {
        switch (history.location.hash) {
            case '#amenities':
                navTitle = 'Spot amenities';
                break;
            case '#details':
                navTitle = 'Edit spot details';
                break;
            case '#info':
                navTitle = 'Pricing and info';
                break;
            case '#performance':
                navTitle = 'Spot performance';
                break;
            case '#photos':
                navTitle = 'Spot photos';
                break;
            case '#preferences':
                navTitle = 'Spot preferences';
                break;
            case '#memberships':
                navTitle = 'Memberships';
                break;
            default:
                break;
        }
    }

    if (isStartListings && !isCityPage) {
        const q = myUrl.split('/')[2];
        const subTypes = Object.keys(SUB_TYPE_PARK_LISTINGS).map((k) => SUB_TYPE_PARK_LISTINGS[k]);
        isListing = !!subTypes.find((s) => s.route === q);
    }

    if (isSpotDetail && !navTitle) {
        navTitle = 'Explore spots';
    }

    const backOrPush = () => {
        if (history.action === 'PUSH') {
            history.goBack();
        } else {
            history.push(dataUrl.link);
        }
        return;
    };

    const backButtonAction = () => {
        if (isReserve) {
            const spotId = myUrl.split('/')[2];
            const src = history.location.search ? `#${history.location.search.split('=')[1]}` : '';

            if (history.length > 1) {
                history.goBack();
            } else {
                window.location.replace(RouteFormatter.listings({ first: `${spotId}${src}` }));
            }
            return;
        }
        if (isFavorites) {
            backOrPush();
            return;
        }
        if (isEditSpot && !history.location.hash) {
            history.push(RouteFormatter.hostAccount());
            return;
        }
        if (isSpotDetail) {
            if (history.action === 'PUSH') {
                history.goBack();
            } else {
                history.replace(RouteFormatter.listings({}));
            }
        } else if (isSpotDetail && !spot.id) {
            history.goBack();
        } else {
            if (dataUrl.link) {
                history.push(dataUrl.link);
            } else {
                history.goBack();
            }
        }
    };

    useEffect(() => {
        if (String(channelId).indexOf('CH') !== 0) {
            if (userDetails?.hostMode) {
                myUserChatData && setMyInter(myUserChatData);
            } else {
                spotData && setMyInter(spotData && spotData.host);
            }
        } else {
            interlocutor && setMyInter(interlocutor);
        }
    }, [myUserChatData, spotData, interlocutor]);

    useEffect(() => {
        if (isReservationList && history.location.search) {
            if (history.location.search.includes('from')) {
                setFilterCount(1);
            } else {
                setFilterCount(0);
            }
        } else if (isReservationList && !history.location.search) {
            setFilterCount(0);
        }
        if (isMenuOpened) {
            toggleMenu();
        }
    }, [history.location.search]);

    const toggleResFilter = () => setFilterOpen(!filterOpen);

    const handleResFilterSubmit = (filters) => {
        setFilter(filters);
        setFilterOpen(false);
    };

    const toggleMapViewMode = (val) => {
        setMode(val ? LISTING_SEARCH_MODS.MAP_VIEW : LISTING_SEARCH_MODS.LIST_VIEW);
    };

    const addNewSpotFilter = () => {
        const { lat, lng } = center || {};
        filtersToAlert(lat, lng);

        if (isAuthenticated) {
            history.push(RouteFormatter.newSpotAlerts());
        } else {
            window.location = RouteFormatter.newSpotAlerts();
        }
    };

    const navigationConfig = React.useMemo(
        () =>
            [
                !isAuthenticated && NAV_ITEMS.LOGIN,
                isAuthenticated && NAV_ITEMS.VISIT_LIST,
                NAV_ITEMS.SUBSCRIPTIONS,
                isAuthenticated && { ...NAV_ITEMS.GUEST_MESSAGES, notification: hasUnconsumedMessage.length > 0 },
                isAuthenticated && NAV_ITEMS.ACCOUNT,
                NAV_ITEMS.EXPLORE_SPOTS,
                isAuthenticated && NAV_ITEMS.INVITE_FRIENDS,
                isAuthenticated && NAV_ITEMS.FAVORITES,
                hasSpots ? NAV_ITEMS.SWITCH_TO_HOST : NAV_ITEMS.HOST_WITH_SNIFF,
                NAV_ITEMS.SEPARATOR,
                NAV_ITEMS.TRUST_SAFETY,
                NAV_ITEMS.HELP_CENTER,
                NAV_ITEMS.BLOG,
                isAuthenticated && NAV_ITEMS.SEPARATOR,
                isAuthenticated && NAV_ITEMS.SIGN_OUT,
            ].filter((item) => !!item),
        [isAuthenticated, userDetails, hasUnconsumedMessage]
    );

    useEffect(() => {
        if (isProfile && userName) {
            setProfileName(userName);
        }
    }, [userName]);

    const scrollTop = useCallback((e) => {
        const window = e.currentTarget;
        if (y < 57 || window.scrollY < 57 || y > window.scrollY) {
            setShowNav(true);
        } else if (window.scrollY > 56 && y + 16 < window.scrollY) {
            setShowNav(false);
        }
        y = window.scrollY;
    });

    useEffect(() => {
        if (canHideNavBar) {
            y = window.scrollY;
            window.addEventListener('scroll', scrollTop);
            return () => {
                window.removeEventListener('scroll', scrollTop);
            };
        }
    }, [scrollTop]);

    useEffect(() => {
        if (isProfile) {
            const userId = myUrl.split('/')[2];
            getUserName({ variables: { id: userId } });
        }
        const channelId = myUrl.split('/')[2];
        if (isMessage && String(channelId).indexOf('CH') != 0) {
            if (userDetails?.hostMode) {
                getUserDetailsShort({ variables: { id: channelId } });
            } else {
                getSpotData({ variables: { id: channelId } });
            }
        }
        setShowNav(true);
    }, [isMessage, myUrl]);

    useEffect(() => {
        if (userDetails.id && userDetails.latitude && userDetails.longitude) {
            setUserLocation({
                location: { lat: userDetails.latitude, lng: userDetails.longitude },
                userLocationConfirm: true,
            });
        }
    }, [userDetails, isAuthenticated]);

    useEffect(() => {
        if (isMobile) {
            const htmlEle = document.documentElement;
            if (isMenuOpened) {
                htmlEle.classList.add('root-html-focused');
            } else {
                htmlEle.classList.remove('root-html-focused');
            }
            return () => htmlEle.classList.remove('root-html-focused');
        }
    }, [isMobile, isMenuOpened]);

    useEffect(() => {
        if (!isMobile) {
            return;
        }

        if (userDetails?.id && myUrl == RouteFormatter.listings({})) {
            const hashed = window.location.hash;
            if (hashed == '#' + AUTH_TESTS.TOGGLE_FILTERS) {
                setFilterOpen(true);
            }
        }

        const isBotCrawl = isbot(navigator.userAgent);

        const init = async () => {
            const isTopBarTest = await getTestWithProbSSR({
                getProbTest,
                cookie: COOKIE_PARAM_NAME.SNIFF_TOP_BAR_SIGNUP_5019,
                userTestGroup: USER_TEST_GROUP.SNIFF_TOP_BAR_SIGNUP_5019,
            });

            if (isTopBarTest !== null) {
                setIsBarSignupTest(isTopBarTest);
                mutateTopBarSignup({ variables: { testGroup: isTopBarTest, sessionUuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID) } });
            }
        };

        !isBotCrawl && isListing && !userDetails?.id && init();
    }, []);

    // WHEN IN DESKTOP
    if (!isMobile) {
        return (
            <NavigationLayout>
                <div className={b('left')}>
                    <NavigationLogo />
                </div>
                {!isHost && isShowMoreSpotsBtn && showSpotBtn && (
                    <SniffButton onClick={() => (window.location = RouteFormatter.listings({}))}>Explore more spots</SniffButton>
                )}
                <div className={b('right')}>
                    {!isHost && <RenderHostButton {...{ history, hasSpots, hasSpotNotLive }} />}
                    <HeaderLogoWrapper config={navConfig || navigationConfig} toggleMenu={toggleMenu} isMenuOpened={isMenuOpened} isFixed={isFixed} />
                </div>
            </NavigationLayout>
        );
    }

    // WHEN IN MOBILE
    if (isMobile) {
        return (
            <NavigationLayout
                className={`${isFixed ? 'w-100' : ''} ${showNav || isMenuOpened || isListing ? 'mob-show' : 'mob-hide'} ${
                    isListing && !inputFocus ? 'mob-search-focus' : ''
                } ${isSpotTopBar ? 'has-top-bar' : ''} ${!isAuthenticated && isListing ? 'is-listing' : ''}`}
                style={topBarHeight}
                isSmartBannerShow
            >
                {!isAuthenticated && <MobileTopBarSignup {...{ isBarSignupTest }} />}
                <div className={b('left')}>
                    {/* WHEN IN HOME */}
                    {(isHome || isApp || isSniffpassFreeTrial) && <NavigationLogo full />}

                    {/* WHEN IN BLOG */}
                    {isBlog && <NavigationLogo />}

                    {/* WHEN IN LISTING */}
                    {isListing &&
                        (isHome ? (
                            <HeaderLogoWrapper
                                config={navConfig || navigationConfig}
                                toggleMenu={toggleMenu}
                                isMenuOpened={isMenuOpened}
                                isAuthenticated={isAuthenticated}
                                className="listing-unauth-menu"
                                isListing={isListing}
                                menuIcon={isAuthenticated}
                            />
                        ) : isAuthenticated ? (
                            <RenderMobileBack {...{ backButtonAction, isListing }} />
                        ) : (
                            <HeaderLogoWrapper
                                toggleMenu={toggleMenu}
                                isMenuOpened={isMenuOpened}
                                isAuthenticated={false}
                                className="listing-unauth-menu"
                                isListing={isListing}
                                isBarSignupTest={isBarSignupTest}
                            />
                        ))}

                    {/* WHEN IN NOT HOME + NOT LISTING + NOT BLOG */}
                    {!isHome && !isListing && !isBlog && !isSniffpassFreeTrial && (
                        <>
                            {isHostDash ? <NavigationLogo isHost /> : <RenderMobileBack backButtonAction={backButtonAction} />}
                            {isMessage ? (
                                <ChatboxHeader {...{ myInterlocutor, history }} />
                            ) : isProfile ? (
                                <div className="nav-title snif-s1 snif-medium">{profileName}</div>
                            ) : (
                                <div className={`nav-title snif-s1 snif-medium ${isSpotDetail ? 'spot-detail' : ''}`}>{navTitle}</div>
                            )}
                        </>
                    )}
                    {isListing && (
                        <RenderListingHeader
                            {...{
                                inputFocus,
                                isSmartBannerShow,
                                isMobile,
                                mode,
                                filterOpen,
                                toggleMapViewMode,
                                toggleResFilter,
                                addNewSpotFilter,
                                onGetSpotsListMobile,
                                toggleMenu,
                                isMenuOpened,
                                setInputFocus,
                            }}
                        />
                    )}
                </div>
                {!isListing && !isMessage && (
                    <RenderMobileDefaultRight
                        {...{
                            spot,
                            filter,
                            history,
                            hasSpots,
                            calSpotId,
                            navConfig,
                            toggleMenu,
                            filterOpen,
                            filterCount,
                            publishedSpot,
                            hasSpotNotLive,
                            toggleResFilter,
                            navigationConfig,
                            handleResFilterSubmit,
                            isBlog,
                            isHome,
                            isFixed,
                            isBlogSub,
                            isCalendar,
                            isEditSpot,
                            isResDetail,
                            isHostInbox,
                            isSpotDetail,
                            isMenuOpened,
                            isMemberships,
                            isAuthenticated,
                            isReservationList,
                        }}
                    />
                )}
            </NavigationLayout>
        );
    }
};

Index.propTypes = {
    isMenuOpened: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
};

export default compose(
    withReservationsFilters({ asHost: true }),
    withRouter,
    connect(
        (state, { location, hostMode }) => {
            const channelId = location.pathname.split('/')[2];
            let dialogId = channelId;
            let spotId;
            let guestId;
            let interlocutor = null;
            let interspot = null;

            // fallback for old links
            if (String(channelId).length !== 34 || String(channelId).indexOf('CH') !== 0) {
                if (hostMode) {
                    spotId = queryString.parse(location.search).spot_id;
                    guestId = channelId;
                    dialogId = selectChannelIdByGuestAndSpot(state, guestId, spotId);
                } else {
                    spotId = channelId;
                    dialogId = selectDialogIdBySpotId(state, spotId);
                }
            } else {
                interlocutor = selectDialogInterlocutor(state, dialogId);
                interspot = selectDialogSpot(state, dialogId);
            }
            return {
                channelId,
                mySpot: state.spot,
                userDetails: state.userDetails,
                isAuthenticated: state.user.data !== null && state.user.data,
                isSmartBannerShow: state.app.isSmartBannerShow,
                isShowMoreSpotsBtn: state.app.isShowMoreSpotsBtn,
                platform: state.app.platform,
                userLoaded: state.apiState.getUserDetailsState.success,
                center: state.map.center,
                interlocutor,
                interspot,
                hostMode,
                blockedUserIds: selectBlockedUserIds(state),
                messageList: state.messaging.messageList,
            };
        },
        (dispatch) => ({
            updateLocation: (lat, lng, state, city) => dispatch(updateUserLocation(lat, lng, state, city)),
            filtersToAlert: (lat, lon) => dispatch(setAlertFromFilters(lat, lon)),
            setUserLocation: (v) => dispatch(setUserLocationThunk(v)),
            onGetSpotsListMobile: ({ center, bounds, filters, forceCenter, force }) =>
                dispatch(
                    getSpotsListBoxMobile({
                        center: center,
                        bounds: bounds,
                        filters: filters,
                        forceCenter: forceCenter,
                        force: force,
                    })
                ),
        })
    )
)(Index);
