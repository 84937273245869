import PropTypes from 'prop-types';
import React from 'react';
import NavigationGuest from '../navigation-guest';

const CommonNavigation = ({ toggleMenu, isMenuOpened, config }) => {
    return (
        <NavigationGuest
            navConfig={config}
            toggleMenu={toggleMenu}
            isMenuOpened={isMenuOpened}
            isShowMoreSpotsBtn={false}
            isHost
            showSpotBtn
        />
    );
};

CommonNavigation.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    isMenuOpened: PropTypes.bool,
    config: PropTypes.arrayOf(PropTypes.object),
};

export default CommonNavigation;
