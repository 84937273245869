import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserDetails } from '../../actions/user.actions';
import Preloader from '../preloader';

const UserLoader = ({ userLoading, getUserFull, children }) => {
    const [firstLoaded, setFirstLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            await getUserFull();
            setFirstLoaded(true);
        })();
    }, []);

    if ((userLoading && !firstLoaded) || !firstLoaded) {
        return <Preloader fullWindow />;
    }
    return children;
};

export default connect(
    (state) => ({
        userLoading: state.user.loading,
    }),
    {
        getUserFull: getUserDetails,
    }
)(UserLoader);
