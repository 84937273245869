import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateSimpleMessageList } from '../../actions/messaging.actions';
import { selectUserDetailsState } from '../../selectors/user.selector';
import TwilioService from '../twilio.service';
import useGetMsgListSimple from './useGetMsgListSimple';
import { DIALOGS_LIMIT } from '../../components/messaging/consts';

export default function useTwillioMsg(token) {
    const dispatch = useDispatch();
    const userDetails = useSelector(selectUserDetailsState);
    const { getMsgListSimple } = useGetMsgListSimple();
    const [msgCol, setMsgCol] = useState([]);
    const [newMsg, setNewMsg] = useState(null);

    const initMsg = async () => {
        const res = await Promise.allSettled([
            getMsgListSimple({
                variables: {
                    page: 1,
                    limit: DIALOGS_LIMIT,
                    channelType: 'FOR_GUEST',
                },
                fetchPolicy: 'network-only',
            }),
            getMsgListSimple({
                variables: {
                    page: 1,
                    limit: DIALOGS_LIMIT,
                    channelType: 'FOR_HOST',
                },
                fetchPolicy: 'network-only',
            }),
        ]);

        const colGuest = res[0].status == 'fulfilled' ? res[0].value.data?.me?.channelsPaged?.collection : [];
        const colHost = res[1].status == 'fulfilled' ? res[1].value.data?.me?.channelsPaged?.collection : [];
        const col = [...colGuest, ...colHost];

        setMsgCol(col);
        dispatch(actionUpdateSimpleMessageList(col));
    };

    useEffect(() => {
        initMsg();
    }, []);

    useEffect(() => {
        if (!token) {
            return;
        }

        const init = async () => {
            const chatClient = new TwilioService();
            await chatClient.createClient(token);

            chatClient.on('messageAdded', async (m) => {
                setNewMsg(m);
            });
        };
        init();
    }, [token]);

    useEffect(() => {
        if (newMsg) {
            const foundMsgIdx = msgCol.findIndex((m) => m.sid == newMsg.conversation.sid);
            const newCol = structuredClone(msgCol);
            const isMe = newMsg.author == userDetails.id;

            if (foundMsgIdx >= 0) {
                newCol[foundMsgIdx].hasUnreadMessage = !isMe;
                setMsgCol(newCol);
                dispatch(actionUpdateSimpleMessageList(newCol));
            } else {
                if (isMe) {
                    return;
                }
                const newChat = {
                    sid: newMsg.conversation.sid,
                    hasUnreadMessage: true,
                    interlocutor: {
                        id: newMsg.author,
                    },
                };
                const tempCol = [...newCol, newChat];
                setMsgCol(tempCol);
                dispatch(actionUpdateSimpleMessageList(tempCol));
            }
        }
    }, [newMsg]);

    return msgCol;
}
