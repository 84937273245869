import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router';
import { parseQuery } from '../../helpers/routing';

const withQueryParams = compose(
    withRouter,
    withProps(({ location }) => ({
        parsedRouteParams: parseQuery(location.search)
    }))
);

export default withQueryParams;
