import React from 'react';
import { connect } from 'react-redux';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('navigation-layout');

const NavigationLayout = ({ children, className, isSmartBannerShow, isShowMoreSpotsBtn, style }) => {
    return (
        <header className={b({ offset: isSmartBannerShow, shrink: isShowMoreSpotsBtn }).mix(className)} style={style}>
            {children}
        </header>
    );
};

export default connect((state) => ({
    isShowMoreSpotsBtn: state.app.isShowMoreSpotsBtn,
}))(NavigationLayout);
