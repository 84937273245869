import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import usePrevious from '../../services/hooks/usePrevious';
import useTwillioMsg from '../../services/hooks/useTwillioMsg';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { Route, Switch } from 'react-router';
import { initMessaging } from '../../actions/messaging.actions';
import { RouteFormatter } from '../../routes';
import { getUserDetails } from '../../actions/user.actions';
import { selectIsAuthenticated } from '../../selectors/user.selector';
import NavigationGuestUnauth from '../navigation-guest-unauth';
import NavigationGuest from '../navigation-guest';
import NavigationHost from './navigation.host';
import './navigation.scss';

const RenderHostNavigation = ({ matchProps, toggleMenu, isOpen }) => {
    return <NavigationHost toggleMenu={toggleMenu} isMenuOpened={isOpen} {...matchProps} />;
};

const RenderGuestNavigation = ({ isAuthenticated, isMobile, toggleMenu, isOpen, cityTest }) => {
    if (isAuthenticated || isMobile) {
        return <NavigationGuest toggleMenu={toggleMenu} isMenuOpened={isOpen} cityTest={cityTest} />;
    } else {
        return <NavigationGuestUnauth />;
    }
};

const RenderHomeNavigation = ({ isAuthenticated, isMobile, toggleMenu, isOpen }) => {
    if (isAuthenticated || isMobile) {
        return <NavigationGuest toggleMenu={toggleMenu} isMenuOpened={isOpen} isFixed showSpotBtn />;
    } else {
        return <NavigationGuestUnauth />;
    }
};

const RenderNoNavigation = () => null;

const Navigation = ({ user, isAuthenticated, getUserDetails, initMessaging, cityTest, token }) => {
    isAuthenticated && useTwillioMsg(token);
    const isMobile = useMobileScreen();
    const [isOpen, setIsOpen] = useState(false);
    const prevUser = usePrevious(user);

    useEffect(() => {
        initMessaging();
        !isMobile && document.addEventListener('click', handleClickOutside);

        return () => {
            !isMobile && document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!prevUser?.id && user?.id) {
            initUser();
        }
    }, [user]);

    const handleClickOutside = () => setIsOpen(false);

    const initUser = () => {
        if (isAuthenticated) {
            initMessaging();
            getUserDetails();
        }
    };

    const toggleMenu = (e) => {
        e?.nativeEvent?.stopImmediatePropagation();
        setIsOpen(!isOpen);
    };

    return (
        <Switch>
            <Route exact path={RouteFormatter.finishYourAccount()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.hostLanding()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.hostLandingShort()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.signIn()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.signUp()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.signUpHost()} render={() => <RenderNoNavigation />} />
            <Route exact path={RouteFormatter.signUpRef()} render={() => <RenderNoNavigation />} />
            <Route path={RouteFormatter.spotCompletion()} render={() => <RenderNoNavigation />} />
            <Route path={RouteFormatter.newSpot()} render={() => <RenderNoNavigation />} />
            <Route path={RouteFormatter.createSpot()} render={() => <RenderNoNavigation />} />
            <Route
                path={RouteFormatter.postPublishSpot()}
                render={() => (window.innerWidth > 768 ? <RenderHostNavigation {...{ toggleMenu, isOpen }} /> : <RenderNoNavigation />)}
            />
            <Route
                path={RouteFormatter.reserve()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderNoNavigation />
                    ) : (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    )
                }
            />
            <Route
                path={RouteFormatter.membershipsDetail()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                path={RouteFormatter.subscriptionsNew()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                path={RouteFormatter.emailVerified()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                path={RouteFormatter.phoneVerified()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                exact
                path={RouteFormatter.visitDetails()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                exact
                path={RouteFormatter.visitDetailsReport()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route
                exact
                path={RouteFormatter.review()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderNoNavigation />
                    )
                }
            />
            <Route path={RouteFormatter.hostAccount()} render={(matchProps) => <RenderHostNavigation {...{ matchProps, toggleMenu, isOpen }} />} />
            <Route
                exact
                path={RouteFormatter.home()}
                render={() =>
                    window.innerWidth > 768 ? (
                        <RenderHomeNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    ) : (
                        <RenderHomeNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen }} />
                    )
                }
            />
            <Route path={RouteFormatter.calendar()} render={() => <RenderHostNavigation {...{ toggleMenu, isOpen }} />} />
            <Route path={RouteFormatter.hostMessages()} render={() => <RenderHostNavigation {...{ toggleMenu, isOpen }} />} />
            <Route render={() => <RenderGuestNavigation {...{ isAuthenticated, isMobile, toggleMenu, isOpen, cityTest }} />} />
        </Switch>
    );
};

export default withRouter(
    connect(
        (state) => ({
            user: state.userDetails,
            isAuthenticated: selectIsAuthenticated(state),
            token: state.messaging.token,
        }),
        {
            getUserDetails,
            initMessaging,
        }
    )(Navigation)
);
