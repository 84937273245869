import ReactOnRails from 'react-on-rails';

import App from '../../app';

// import HelloWorld from '../bundles/HelloWorld/components/HelloWorld'
// import SniffRouter from '../bundles/HelloWorld/components/ClientRouter'

// import SharedReduxStore from '../stores/SharedReduxStore'

// ReactOnRails.registerStore({
//     store: SharedReduxStore,
// })

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    // HelloWorld,
    // SniffRouter,
    App,
});
